var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","width":"90%"},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('v-card',{attrs:{"color":"yellow lighten-5"}},[_c('v-card-title',[_c('i18n',{attrs:{"path":"dialogs.clientsservices"}}),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.$emit('input', false)}}},[_c('v-icon',[_vm._v("mdi-close-circle")])],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"transparent table-custom",attrs:{"headers":_vm.headers,"items":_vm.items_,"item-key":"idx","footer-props":_vm.foot_props,"no-data-text":_vm.$t('label.nodata')},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [(item)?_c('span',[_vm._v(_vm._s(_vm._f("dt-time")(item.created)))]):_vm._e()]}},{key:"item.workout",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.workout?"mdi-check":""))])]}},{key:"item.service",fn:function(ref){
var item = ref.item;
return [(item)?_c('sc-record-info',{attrs:{"idx":item.service,"store":"services/item"}}):_vm._e()]}},{key:"item.scalar",fn:function(ref){
var item = ref.item;
return [(item.scalar)?_c('span',[_vm._v(_vm._s(item.remainscalar))]):_vm._e()]}},{key:"item.visitcontrol",fn:function(ref){
var item = ref.item;
return [(item.visitcontrol)?_c('span',[_vm._v(_vm._s(item.remainvisits))]):_vm._e()]}},{key:"item.days",fn:function(ref){
var item = ref.item;
return [(item.time)?_c('sc-week-days',{class:'ml-n1 '+(item.testcode==2?'red--text':''),attrs:{"days":item.time.days}}):_vm._e()]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("time_interval")(item.time.hours)))])]}},{key:"item.fromDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("dt-only")(item.fromDate)))]}},{key:"item.testcode",fn:function(ref){
var item = ref.item;
return [_c('ServiceTestField',{attrs:{"item":item}})]}},{key:"item.toDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("dt-only")(item.toDate)))]),(_vm.isCanProlog)?_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.openProlong(item)}}},[_c('v-icon',[_vm._v("mdi-calendar-end")])],1):_vm._e()]}}])})],1)],1),_c('v-dialog',{attrs:{"persistent":"","width":"400"},model:{value:(_vm.d_prolong),callback:function ($$v) {_vm.d_prolong=$$v},expression:"d_prolong"}},[_c('v-card',{staticClass:"yellow lighten-5"},[_c('v-card-title',[_c('i18n',{attrs:{"path":"dialogs.prolongt"},scopedSlots:_vm._u([(_vm.item)?{key:"client",fn:function(){return [_vm._v(_vm._s(_vm.item.name))]},proxy:true}:null],null,true)}),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){_vm.d_prolong=false}}},[_c('v-icon',[_vm._v("mdi-close-circle")])],1)],1),_c('v-card-text',{staticClass:"text-center"},[_c('div',[_c('i18n',{attrs:{"path":"dialogs.prolong"}})],1),_c('v-date-picker',{attrs:{"min":_vm.$moment().format('YYYY-MM-DD')},model:{value:(_vm.prolongto),callback:function ($$v) {_vm.prolongto=$$v},expression:"prolongto"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary","disabled":!_vm.prolongto},on:{"click":_vm.startProlong}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-check")]),_c('i18n',{attrs:{"path":"button.prolong"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }