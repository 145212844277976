var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","width":"90%"},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('v-card',{attrs:{"color":"yellow lighten-5"}},[_c('v-card-title',[_c('i18n',{attrs:{"path":"dialogs.clients_purchases"}}),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.$emit('input', false)}}},[_c('v-icon',[_vm._v("mdi-close-circle")])],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"transparent table-custom",attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"idx","sort-desc":"","sort-by":"created"},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("currency")(item.price)))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!item.usedat && item.sportclub==_vm.$store.getters['session/scidx'] && _vm.$moment().isSameOrBefore(item.to_date, 'date'))?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.reversal(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cart-remove")])],1):_vm._e()]}}],null,true)},[_c('i18n',{attrs:{"path":"tt.reversal"}})],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }